function handleLogEvent(opts) {
  const { eventName, featureArea, elementLocation, elementName, properties } =
    opts;

  window.clientEventLogger &&
    window.clientEventLogger.push({
      eventTimestamp: Date.now(),
      eventData: {
        eventName,
        featureArea,
        elementLocation,
        elementName,
        properties,
      },
    });
}

angular.module('app').directive('tkEventLogging', function () {
  const directiveDefinitionObject = {
    restrict: 'A',
    link(scope, element, attrs) {
      if (element[0].nodeName.toLowerCase() === 'input') {
        return element.bind('keydown keypress', (event) => {
          if (event.which === 13) {
            handleLogEvent(scope.$eval(attrs.tkEventLogging));
            return event.preventDefault();
          }
        });
      }

      // Assuming it's a clickable object
      return element.bind('click', (event) => {
        handleLogEvent(scope.$eval(attrs.tkEventLogging));
        return event.preventDefault();
      });
    },
  };

  return directiveDefinitionObject;
});
