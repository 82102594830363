/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
// The primary concern of this service is to expose the data (i.e. users, projects, etc.)
// in a way that abstracts away how that data is actually fetched or saved back to the server
angular.module('app.services').factory('TKData', [
  '$q',
  '$log',
  '$timeout',
  'DSCacheFactory',
  '$rootScope',
  'Restangular',
  'TKAnalytics',
  'TKSession',
  'TKDateUtil',
  'TKTimeEntryHelper',
  function (
    $q,
    $log,
    $timeout,
    DSCacheFactory,
    $rootScope,
    Restangular,
    TKAnalytics,
    TKSession,
    TKDateUtil,
    TKTimeEntryHelper
  ) {
    const TKData = {};

    Restangular.addFullRequestInterceptor(function (
      element,
      operation,
      route,
      url,
      headers,
      params,
      httpConfig
    ) {
      if (
        ![
          'post',
          'put',
          'delete',
          'remove',
          'patch',
          'update',
          'create',
        ].includes(operation)
      ) {
        return {};
      }
      const token = $('meta[name="csrf-token"]').attr('content');
      headers['X-CSRF-Token'] = token;
      if (window.isIframeView && window.clientState) {
        headers['x-smar-xsrf'] = window.clientState.sessionKey || undefined;
      }
      return { headers };
    });

    const _tmpSessionCache = DSCacheFactory('_tmp_cache', {
      storageMode: 'localStorage',
    });

    // TKData should not return objects with Restangular attributes/methods,
    // nor should it return the same object more than once.
    // This function takes care of both of these things.
    const cloneData = (restangularObject) =>
      Restangular.stripRestangular(restangularObject);

    const deferredAuth = $q.defer();
    const withAuth = (tkDataFunction) =>
      function () {
        if (TKData.authenticated) {
          return tkDataFunction.apply(TKData, arguments);
        } else {
          // This block handles the edge case where
          // a call is made prior to authentication.
          const deferred = $q.defer();
          const $object = (deferred.promise.$object = []);
          const resolve = function (obj) {
            _.assign($object, obj);
            deferred.resolve($object);
          };
          const notify = function (obj) {
            _.assign($object, obj);
            deferred.notify($object);
          };
          const args = arguments;
          deferredAuth.promise.then(function () {
            const promise = tkDataFunction.apply(TKData, args);
            if (promise.$object) {
              promise.$object = $object;
            }
            ((promise != null ? promise.then : undefined)
              ? promise
              : $q.when()
            ).then(resolve, deferred.reject, notify);
          });
          return deferred.promise;
        }
      };

    const initialize = function (session) {
      TKData.authenticated = !!session.connected;
      if (session.connected) {
        deferredAuth.resolve();
        $rootScope.$emit('authenticated');
      }
    };

    $rootScope.$on('sessionChanged', function (event, args) {
      initialize(args.session);
    });

    TKData.projects = Restangular.all('projects');
    TKData.users = Restangular.all('users');
    TKData.leaveTypes = Restangular.all('leave_types');

    TKData.createProject = withAuth((project) =>
      TKData.projects.post(project).then((project) => cloneData(project))
    );

    TKData.updateProject = withAuth((project) =>
      Restangular.one('projects', project.id)
        .put(project)
        .then(function (project) {
          project = cloneData(project);
          $rootScope.$emit('projectUpdated', project);
          return project;
        })
    );

    TKData.deleteProject = withAuth(function (project) {
      const { id } = project;
      return Restangular.one('projects', id)
        .remove()
        .then(function () {
          $rootScope.$emit('projectDeleted', id);
          return id;
        });
    });

    const processPart = function (part) {
      part = cloneData(part);
      if (_.isString(part.content)) {
        part.content = JSON.parse(part.content);
      }
      return part;
    };

    TKData.getProjectParts = withAuth((project) =>
      Restangular.one('projects', project.id)
        .all('parts')
        .getAllPages()
        .then(function (parts) {
          parts = parts.map(processPart);
          return parts;
        })
    );

    TKData.createProjectPart = withAuth(function (project, part) {
      if (!_.isString(part.content)) {
        part.content = JSON.stringify(part.content || { version: 1 });
      }
      return Restangular.one('projects', project.id)
        .all('parts')
        .post(part)
        .then(processPart);
    });

    TKData.removeProjectPart = withAuth((project, part) =>
      Restangular.one('projects', project.id)
        .one('parts', part.id)
        .remove()
        .then(() => part)
    );

    TKData.getProjectLayouts = withAuth((project) =>
      Restangular.one('projects', project.id)
        .all('layouts')
        .getAllPages()
        .then(function (layouts) {
          layouts = layouts.map(cloneData);
          return layouts;
        })
    );

    TKData.createProjectLayout = withAuth((project, layout) =>
      Restangular.one('projects', project.id)
        .all('layouts')
        .post(layout)
        .then(function (layout) {
          const layouts = cloneData(layout);
          return layouts;
        })
    );

    TKData.updateProjectLayout = withAuth((project, layout) =>
      Restangular.one('projects', project.id)
        .one('layouts', layout.id)
        .put(layout)
        .then(function (layout) {
          layout = cloneData(layout);
          return layout;
        })
    );

    TKData.getPartContent = withAuth(function (projectId, partId) {
      const deferred = $q.defer();
      Restangular.one('projects', projectId)
        .all('parts')
        .get(partId)
        .then(function (part) {
          part = processPart(part);
          if (part.content != null ? part.content.attachmentId : undefined) {
            data
              .getAttachment(projectId, part.content.attachmentId)
              .then(function (attachment) {
                part.content.imageURL = attachment.url;
                return deferred.resolve(part.content);
              });
          } else {
            deferred.resolve(part.content);
          }
        });
      return deferred.promise;
    });

    TKData.updatePartContent = withAuth(function (projectId, partId, content) {
      content.version = 1;
      return Restangular.one('projects', projectId)
        .one('parts', partId)
        .put({ content: JSON.stringify(content) })
        .then(function (part) {
          part = processPart(part);
          $rootScope.$emit('partContentUpdated', part);
          return part;
        });
    });

    TKData.createAttachment = withAuth((projectId, attachment) =>
      Restangular.one('projects', projectId)
        .all('attachments')
        .post(attachment)
        .then(function (attachment) {
          attachment = cloneData(attachment);
          return attachment;
        })
    );

    TKData.getAttachment = withAuth((projectId, attachmentId) =>
      Restangular.one('projects', projectId)
        .all('attachments')
        .get(attachmentId)
        .then(function (attachment) {
          attachment = cloneData(attachment);
          return attachment;
        })
    );

    // POST /api/v1/projects/:id/invitations
    TKData.sendShareInvite = withAuth(function (projectId, email) {
      const deferred = $q.defer();
      TKData.projects
        .get(projectId)
        .then((project) =>
          project
            .post('invitations', { email })
            .then((newInvite) => deferred.resolve(newInvite))
        );
      return deferred.promise;
    });

    TKData.getLeaveTypes = withAuth(() =>
      TKData.cachedLeaveTypeCollection != null
        ? TKData.cachedLeaveTypeCollection
        : (TKData.cachedLeaveTypeCollection = Restangular.all(
            'leave_types'
          ).getAllPages({ with_archived: true }))
    );

    TKData.getRealProjects = withAuth(() =>
      TKData.cachedRealProjectCollection != null
        ? TKData.cachedRealProjectCollection
        : (TKData.cachedRealProjectCollection = Restangular.all(
            'projects'
          ).getAllPages({ with_phases: true, per_page: 1000 }))
    );

    TKData.getUsers = withAuth(() =>
      TKData.cachedUsers != null
        ? TKData.cachedUsers
        : (TKData.cachedUsers = Restangular.all('users').getAllPages({
            fields: 'tags',
          }))
    );

    TKData.getHolidays = withAuth(() =>
      TKData.cachedHolidays != null
        ? TKData.cachedHolidays
        : (TKData.cachedHolidays = Restangular.all('holidays').getAllPages())
    );

    TKData.getClients = withAuth(() =>
      TKData.cachedClients != null
        ? TKData.cachedClients
        : (TKData.cachedClients = Restangular.all('clients').getAllPages())
    );

    TKData.getDisciplines = withAuth(() =>
      TKData.cachedDisciplines != null
        ? TKData.cachedDisciplines
        : (TKData.cachedDisciplines =
            Restangular.all('disciplines').getAllPages())
    );

    TKData.getSettings = withAuth(function () {
      const deferred = $q.defer();
      Restangular.one('settings')
        .getAllPages()
        .then(function (data) {
          const hash = {};
          _.each(data, (setting) => (hash[setting.name] = setting.value));
          return deferred.resolve(hash);
        });
      return deferred.promise;
    });

    TKData.getUserSettings = withAuth((userId) =>
      Restangular.one('users', userId).getAllPages('settings')
    );

    TKData.createUserSettings = withAuth((userId, name, settings, isPublic) =>
      Restangular.one('users', userId).post('settings', {
        name,
        value: settings,
        private: !isPublic,
      })
    );

    TKData.deleteUserSetting = withAuth((userId, setting) =>
      Restangular.one('users', userId).one('settings', setting.id).remove()
    );

    TKData.getApprovals = withAuth((startDate, endDate) =>
      Restangular.one('approvals')
        .getAllPages(null, {
          per_page: 1000,
          fields: 'approvable',
          filter_field: 'approvables_dates',
          filter_list: `${TKDateUtil.toRubyDate(
            startDate
          )},${TKDateUtil.toRubyDate(endDate)}`,
        })
        .then((approvals) =>
          _.filter(
            approvals,
            (approval) => approval.approvable && approval.approvable.hours !== 0
          )
        )
    );

    TKData.setApprovablesStatus = withAuth(function (
      userId,
      approvables,
      status,
      unapprove,
      type
    ) {
      const list = _.map(approvables, (approvable) => ({
        id: approvable.id,
        updated_at: approvable.updated_at,
        type,
      }));

      const queryParams = {
        status,
      };
      if (unapprove) {
        queryParams.unapproving = true;
      }

      if (list.length) {
        return Restangular.all('approvals').customPOST(
          { approvables: list },
          null,
          queryParams
        );
      }

      return $q.when();
    });

    TKData.getTimeEntry = withAuth((userId, timeEntryId) =>
      TKData.cachedTimeEntries[timeEntryId] != null
        ? TKData.cachedTimeEntries[timeEntryId]
        : (TKData.cachedTimeEntries[timeEntryId] = Restangular.one(
            'users',
            userId
          )
            .one('time_entries', timeEntryId)
            .get())
    );

    TKData.getAssignableSearchResults = withAuth(function (input) {
      const params = {
        query: input,
        search_by_client: true,
        with_archived: false,
        detailed: true,
        fields: 'children',
        per_page: 100,
      };

      return Restangular.all('assignables').customGET('search', params);
    });

    TKData.getTimeEntries = withAuth(function (
      userId,
      startDate,
      endDate,
      withSuggestions,
      includeLocked
    ) {
      const params = {
        with_suggestions: withSuggestions,
        fields: 'approvals',
        from: TKDateUtil.toRubyDate(startDate),
        to: TKDateUtil.toRubyDate(endDate),
        per_page: 1000,
      };
      if (includeLocked) {
        params.include_locked = true;
      }
      const cache = TKData.cachedTimeEntries || (TKData.cachedTimeEntries = {});
      const cacheKey = _.tk.objectToQueryString(params);
      return (cache[cacheKey] = Restangular.one('users', userId)
        .getAllPages('time_entries', params)
        .then(function (timeEntries) {
          _.each(timeEntries, function (timeEntry) {
            cache[timeEntry.id] = $q.when(timeEntry);
          });
          // invalidate collection cache on promise completion
          cache[cacheKey] = null;
          return timeEntries;
        }));
    });

    TKData.getEveryonesTimeEntries = withAuth(function (startDate, endDate) {
      const params = {
        fields: 'approvals',
        from: TKDateUtil.toRubyDate(startDate),
        to: TKDateUtil.toRubyDate(endDate),
        per_page: 1000,
      };
      const cache = TKData.cachedTimeEntries || (TKData.cachedTimeEntries = {});
      const cacheKey = _.tk.objectToQueryString(params);
      return (cache[cacheKey] = Restangular.all('time_entries')
        .getAllPages(params)
        .then(function (timeEntries) {
          _.each(timeEntries, function (timeEntry) {
            cache[timeEntry.id] = $q.when(timeEntry);
          });
          // invalidate collection cache on promise completion
          cache[cacheKey] = null;
          return timeEntries;
        }));
    });

    TKData.deleteTimeEntry = withAuth(
      (
        timeEntry // $log.info "Deleting a time entry"
      ) =>
        Restangular.one('users', timeEntry.user_id)
          .one('time_entries', timeEntry.id)
          .remove()
          .then(function (response) {
            if (TKTimeEntryHelper.isConfirmed(timeEntry)) {
              TKAnalytics.timeEntry['delete']();
            }
            $rootScope.$emit('timeEntryDeleted', timeEntry);
            return response;
          })
    );

    TKData.createTimeEntry = withAuth(function (timeEntry) {
      // $log.info "Creating time entry"
      const data = _.pick(
        timeEntry,
        'hours',
        'assignable_id',
        'date',
        'notes',
        'task'
      );
      return Restangular.one('users', timeEntry.user_id)
        .post('time_entries', data)
        .then(function (timeEntry) {
          $rootScope.$emit('timeEntryCreated', timeEntry);
          return timeEntry;
        });
    });

    TKData.deletePendingApprovals = withAuth(function (approvable) {
      _.each(
        approvable.approvals != null ? approvable.approvals.data : undefined,
        function (approval) {
          if (approval.status === 'pending') {
            Restangular.one('approvals', approval.id).remove();
          }
        }
      );
      if (approvable.approvals == null) {
        approvable.approvals = {};
      }
      return (approvable.approvals.data = []);
    });

    TKData.updateTimeEntry = withAuth(function (timeEntry) {
      const data = _.pick(timeEntry, 'hours', 'notes', 'task');

      TKData.deletePendingApprovals(timeEntry);

      return Restangular.one('users', timeEntry.user_id)
        .one('time_entries', timeEntry.id)
        .put(data)
        .then(function (response) {
          $rootScope.$emit('timeEntryUpdated', response);
          TKAnalytics.timeEntry.update();
          return response;
        });
    });

    TKData.getExpenses = withAuth((userId, startDate, endDate, includeLocked) =>
      Restangular.one('users', userId)
        .getAllPages('expense_items', {
          fields: 'approvals',
          from: TKDateUtil.toRubyDate(startDate),
          to: TKDateUtil.toRubyDate(endDate),
          include_locked: includeLocked ? true : false,
        })
        .then(function (expenses) {
          if (TKData.cachedExpenses == null) {
            TKData.cachedExpenses = {};
          }
          _.each(expenses, function (expense) {
            TKData.cachedExpenses[expense.id] = $q.when(expense);
          });
          return expenses;
        })
    );

    TKData.createExpense = withAuth(function (expense) {
      const data = _.pick(
        expense,
        'date',
        'amount',
        'notes',
        'category',
        'assignable_id'
      );
      // data.date = TKDateUtil.toRubyDate data.date

      return Restangular.one('users', expense.user_id)
        .post('expense_items', data)
        .then(function (response) {
          TKAnalytics.expenses.create();
          return response;
        });
    });

    TKData.updateExpense = withAuth(function (expense) {
      const data = _.pick(
        expense,
        'date',
        'assignable_id',
        'notes',
        'amount',
        'category'
      );
      if (!data.notes) {
        data.notes = null;
      }
      if (!data.category) {
        data.category = null;
      }

      TKData.deletePendingApprovals(expense);

      return Restangular.one('users', expense.user_id)
        .one('expense_items', expense.id)
        .put(data)
        .then(function (response) {
          TKAnalytics.expenses.update();
          return response;
        });
    });

    TKData.deleteExpense = withAuth((expense) =>
      Restangular.one('users', expense.user_id)
        .one('expense_items', expense.id)
        .remove()
        .then(function (response) {
          TKAnalytics.expenses['delete']();
          return response;
        })
    );

    // Note: This makes a call to the server every time it is called.
    // TODO Implement caching.
    TKData.getBudgetItems = withAuth(function (projectId, itemType) {
      if (!itemType) {
        $log.error('item_type is not specified.');
      }
      return Restangular.one('projects', projectId)
        .getAllPages('budget_items', { item_type: itemType })
        .then(function (budgetItems) {
          budgetItems = budgetItems.map(cloneData);
          return budgetItems;
        });
    });

    TKData.getTimeEntryCategories = withAuth(() =>
      Restangular.all('time_entry_categories').getAllPages()
    );

    TKData.getProjectCategories = withAuth(function (projectId) {
      if (TKData.cachedProjectCategories == null) {
        TKData.cachedProjectCategories = {};
      }
      return TKData.cachedProjectCategories[projectId] != null
        ? TKData.cachedProjectCategories[projectId]
        : (TKData.cachedProjectCategories[projectId] = Restangular.one(
            'projects',
            projectId
          ).getAllPages('time_entry_categories'));
    });

    TKData.getExpenseItemCategories = withAuth(() =>
      Restangular.all('expense_item_categories').getAllPages()
    );

    TKData.getProjectExpenseCategories = withAuth(function (projectId) {
      if (TKData.cachedProjectCategories == null) {
        TKData.cachedProjectCategories = {};
      }
      return TKData.cachedProjectCategories[projectId] != null
        ? TKData.cachedProjectCategories[projectId]
        : (TKData.cachedProjectCategories[projectId] = Restangular.one(
            'projects',
            projectId
          ).getAllPages('expense_item_categories'));
    });

    TKData.getUser = withAuth(function (userId) {
      if (TKData.cachedUsersById == null) {
        TKData.cachedUsersById = {};
      }
      return TKData.cachedUsersById[userId] != null
        ? TKData.cachedUsersById[userId]
        : (TKData.cachedUsersById[userId] =
            Restangular.all('users').get(userId));
    });

    TKData.createBudgetItem = withAuth((projectId, budgetItem) =>
      Restangular.one('projects', projectId)
        .all('budget_items')
        .post(budgetItem)
        .then(function (budgetItem) {
          budgetItem = cloneData(budgetItem);
          $rootScope.$emit('budgetItemCreated', budgetItem);
          return budgetItem;
        })
    );

    TKData.updateBudgetItem = withAuth((projectId, budgetItem) =>
      Restangular.one('projects', projectId)
        .one('budget_items', budgetItem.id)
        .put(budgetItem)
        .then(function (budgetItem) {
          budgetItem = cloneData(budgetItem);
          $rootScope.$emit('budgetItemUpdated', budgetItem);
          return budgetItem;
        })
    );

    TKData.createOrUpdateBudgetItem = withAuth(function (
      projectId,
      budgetItem
    ) {
      if (budgetItem.id) {
        return TKData.updateBudgetItem(projectId, budgetItem);
      } else {
        return TKData.createBudgetItem(projectId, budgetItem);
      }
    });

    TKData.deleteBudgetItem = withAuth((projectId, budgetItem) =>
      Restangular.one('projects', projectId)
        .one('budget_items', budgetItem.id)
        .remove()
        .then(function () {
          budgetItem = cloneData(budgetItem);
          $rootScope.$emit('budgetItemDeleted', budgetItem);
          return budgetItem;
        })
    );

    initialize(TKSession);

    return TKData;
  },
]);
